
<template>
  <div class="ele-body">



    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="出租车" name="first">
        <el-card shadow="never">
          <el-form ref="form" :model="labelDriver" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelDriver.star == 1 }' @click="handleEvaluat(1)">1星</div>
              <div :class='{ active: labelDriver.star == 2 }' @click="handleEvaluat(2)">2星</div>
              <div :class='{ active: labelDriver.star == 3 }' @click="handleEvaluat(3)">3星</div>
              <div :class='{ active: labelDriver.star == 4 }' @click="handleEvaluat(4)">4星</div>
              <div :class='{ active: labelDriver.star == 5 }' @click="handleEvaluat(5)">5星</div>
            </div>
            <div class="innerBox">

              <el-form-item label="id：" style="display:none;">
                <el-input v-model="labelDriver.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelDriver.tab1" class="w-600"></el-input>
              </el-form-item>

              <el-form-item label="评论标签二：">
                <el-input v-model="labelDriver.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelDriver.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelDriver.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelDriver.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelDriver.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>

            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelDriver.type)"
                  v-if="permission.includes('sys:orderevaluation:chuzu')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="超市" name="second">
        <el-card shadow="never">
          <el-form ref="form" :model="labelMarker" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelMarker.star == 1 }' @click="handleMarker(1)">1星</div>
              <div :class='{ active: labelMarker.star == 2 }' @click="handleMarker(2)">2星</div>
              <div :class='{ active: labelMarker.star == 3 }' @click="handleMarker(3)">3星</div>
              <div :class='{ active: labelMarker.star == 4 }' @click="handleMarker(4)">4星</div>
              <div :class='{ active: labelMarker.star == 5 }' @click="handleMarker(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id：" style="display:none">
                <el-input v-model="labelMarker.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelMarker.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labelMarker.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelMarker.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelMarker.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelMarker.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelMarker.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelMarker.type)"
                  v-if="permission.includes('sys:orderevaluation:chaoshi')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="帮我买" name="three">
        <el-card shadow="never">
          <el-form ref="form" :model="labelCode" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelCode.star == 1 }' @click="handleCode(1)">1星</div>
              <div :class='{ active: labelCode.star == 2 }' @click="handleCode(2)">2星</div>
              <div :class='{ active: labelCode.star == 3 }' @click="handleCode(3)">3星</div>
              <div :class='{ active: labelCode.star == 4 }' @click="handleCode(4)">4星</div>
              <div :class='{ active: labelCode.star == 5 }' @click="handleCode(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id：" style="display:none">
                <el-input v-model="labelCode.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelCode.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labelCode.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelCode.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelCode.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelCode.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelCode.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelCode.type)"
                  v-if="permission.includes('sys:orderevaluation:bangwomai')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="取送件" name="four" style="display:none">
        <el-card shadow="never">
          <el-form ref="form" :model="labelMarker" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labeldDelivery.star == 1 }' @click="handleDelivery(1)">1星</div>
              <div :class='{ active: labeldDelivery.star == 2 }' @click="handleDelivery(2)">2星</div>
              <div :class='{ active: labeldDelivery.star == 3 }' @click="handleDelivery(3)">3星</div>
              <div :class='{ active: labeldDelivery.star == 4 }' @click="handleDelivery(4)">4星</div>
              <div :class='{ active: labeldDelivery.star == 5 }' @click="handleDelivery(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id：">
                <el-input v-model="labeldDelivery.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labeldDelivery.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labeldDelivery.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labeldDelivery.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labeldDelivery.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labeldDelivery.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labeldDelivery.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labeldDelivery.type)"
                  v-if="permission.includes('sys:orderevaluation:qusongjian')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="拼个车" name="five">
        <el-card shadow="never">
          <el-form ref="form" :model="labelCarpull" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelCarpull.star == 1 }' @click="handleCarpull(1)">1星</div>
              <div :class='{ active: labelCarpull.star == 2 }' @click="handleCarpull(2)">2星</div>
              <div :class='{ active: labelCarpull.star == 3 }' @click="handleCarpull(3)">3星</div>
              <div :class='{ active: labelCarpull.star == 4 }' @click="handleCarpull(4)">4星</div>
              <div :class='{ active: labelCarpull.star == 5 }' @click="handleCarpull(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id:">
                <el-input v-model="labelCarpull.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelCarpull.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labelCarpull.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelCarpull.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelCarpull.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelCarpull.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelCarpull.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelCarpull.type)"
                  v-if="permission.includes('sys:orderevaluation:pinche')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="叫餐" name="six">
        <el-card shadow="never">
          <el-form ref="form" :model="labelFood" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelFood.star == 1 }' @click="handleFood(1)">1星</div>
              <div :class='{ active: labelFood.star == 2 }' @click="handleFood(2)">2星</div>
              <div :class='{ active: labelFood.star == 3 }' @click="handleFood(3)">3星</div>
              <div :class='{ active: labelFood.star == 4 }' @click="handleFood(4)">4星</div>
              <div :class='{ active: labelFood.star == 5 }' @click="handleFood(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id：">
                <el-input v-model="labelFood.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelFood.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labelFood.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelFood.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelFood.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelFood.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelFood.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelFood.type)"
                  v-if="permission.includes('sys:orderevaluation:jiaocan')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="预约单" name="seven">
        <el-card shadow="never">
          <el-form ref="form" :model="labelAppointment" label-width="120px">
            <div class="innerTitle d-flex">
              <div :class='{ active: labelAppointment.star == 1 }' @click="handleAppointment(1)">1星</div>
              <div :class='{ active: labelAppointment.star == 2 }' @click="handleAppointment(2)">2星</div>
              <div :class='{ active: labelAppointment.star == 3 }' @click="handleAppointment(3)">3星</div>
              <div :class='{ active: labelAppointment.star == 4 }' @click="handleAppointment(4)">4星</div>
              <div :class='{ active: labelAppointment.star == 5 }' @click="handleAppointment(5)">5星</div>
            </div>
            <div class="innerBox">
              <el-form-item label="id：" style="display:none;">

                <el-input v-model="labelAppointment.id" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签一：">
                <el-input v-model="labelAppointment.tab1" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签二：">
                <el-input v-model="labelAppointment.tab2" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签三：">
                <el-input v-model="labelAppointment.tab3" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签四：">
                <el-input v-model="labelAppointment.tab4" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签五：">
                <el-input v-model="labelAppointment.tab5" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="评论标签六：">
                <el-input v-model="labelAppointment.tab6" class="w-600"></el-input>
              </el-form-item>
              <el-form-item label="提示：">
                <div>至少填写一项，设置完标签项，请不要随意修改，标签信息将对应在APP各订单类型中显示</div>
              </el-form-item>
            </div>
            <div class="innerBoxTo">
              <el-form-item style="float:right;">
                <el-button type="primary" @click="save(labelAppointment.type)"
                  v-if="permission.includes('sys:orderevaluation:jiaocan')">提交</el-button>
              </el-form-item>
            </div>
          </el-form>


        </el-card>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SysUser",
  data() {
    return {
      table: { url: '/user/index', where: {} },  // 表格配置
      choose: [],  // 表格选中数据
      editForm: {},  // 表单数据
      checkForm: {},
      dialogTableVisibleCheck: false,
      dialogTableVisibleEdit: false,
      dialogTableVisibleUrgent: false,
      provArr: [],
      cityArr: [],
      districtArr: [],
      urgentList: [],
      imageUrl: false,
      headImg: '',
      activeName: 'first',
      formEdit: {},
      dirverList: [],
      labelDriver: { star: 0 },
      labelMarker: { star: 0 },
      labelCode: { star: 0 },
      labeldDelivery: { star: 0 },
      labelCarpull: { star: 0 },
      labelFood: { star: 0 },
      labelAppointment: { star: 0 },
      activeName: 'second'
    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      var data = JSON.parse(res.data)
      this.provArr = data
    })
    this.getEvaluat()
  },

  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClick(row) {
      if (row.name == "first") {
        this.labelDriver.type = 1
      } else if (row.name == "second") {
        this.labelDriver.type = 2
      } else if (row.name == "three") {
        this.labelDriver.type = 3
      } else if (row.name == "four") {
        this.labelDriver.type = 4
      } else if (row.name == "five") {
        this.labelDriver.type = 5
      } else if (row.name == "six") {
        this.labelDriver.type = 6
      } else if (row.name == "seven") {
        this.labelAppointment.type = 7
      }

    },
    save(type) {
      const loading = this.$loading({ lock: true });
      if (type == 1) {
        //     	this.labelDriver.id=1
        this.formEdit = this.labelDriver;
      } else if (type == 2) {
        //     	this.labelMarker.id = 2;
        this.formEdit = this.labelMarker;
      } else if (type == 3) {
        this.formEdit = this.labelCode;
      } else if (type == 4) {
        this.formEdit = this.labeldDelivery;
      } else if (type == 5) {
        this.formEdit = this.labelCarpull;
      } else if (type == 6) {
        this.formEdit = this.labelFood;
      } else if (type == 7) {
        this.formEdit = this.labelAppointment;
      }
      //console.log(this.labeldDelivery);
      //console.log(this.formEdit)
      this.$http.post('/order/updateOrderEvaluation', this.formEdit).then(res => {
        loading.close();
        let data = res.data
        if (data.code == 1) {
          this.$message({
            message: data.msg,
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      })
    },
    //获取列表
    getEvaluat() {
      let that = this
      this.$http.get('/order/getOrderEvaluation').then(res => {
        let data = res.data
        that.dirverList = data.data
        let arr = data.data
        if (arr.taxi.length != 0) {
          arr.taxi.forEach(function (item) {
            if (item.star == 1) {
              that.labelDriver = item
            }
          })
        }
        if (arr.market.length != 0) {
          arr.market.forEach(function (item) {
            if (item.star == 1) {
              that.labelMarker = item
            }
          })
        }

        if (arr.buy.length != 0) {
          arr.buy.forEach(function (item) {
            if (item.star == 1) {
              that.labelCode = item
            }
          })
        }

        if (arr.send.length != 0) {
          arr.send.forEach(function (item) {
            if (item.star == 1) {
              that.labeldDelivery = item
            }
          })
        }

        if (arr.carpull.length != 0) {
          arr.carpull.forEach(function (item) {
            if (item.star == 1) {
              that.labelCarpull = item
            }
          })
        }

        if (arr.food.length != 0) {
          arr.food.forEach(function (item) {
            if (item.star == 1) {
              that.labelFood = item
            }
          })
        }
        if (arr.appointment.length != 0) {
          arr.appointment.forEach(function (item) {
            if (item.star == 1) {
              that.labelAppointment = item
            }
          })
        }
      })
    },
    //单击出租车星星
    handleEvaluat(index) {
      //console.log(index)
      this.labelDriver.star = index
      this.labelChange(index, 1)
    },
    //单击超市星星
    handleMarker(index) {
      this.labelMarker.star = index
      this.labelChange(index, 2)
    },
    //单击帮我买星星
    handleCode(index) {
      this.labelCode.star = index
      this.labelChange(index, 3)
    },
    //单击取送件星星
    handleDelivery(index) {
      this.labeldDelivery.star = index
      this.labelChange(index, 4)
    },
    //单击拼个车星星
    handleCarpull(index) {
      this.labelCarpull.star = index
      this.labelChange(index, 5)
    },
    //单击叫餐星星
    handleFood(index) {
      this.labelFood.star = index
      this.labelChange(index, 6)

    },
    handleAppointment(index) {
      this.labelAppointment.star = index
      this.labelChange(index, 7)
    },
    labelChange(val, type) {
      let that = this
      if (type == 1) {
        //    	//console.log(that.dirverList.taxi[val-1])
        that.labelDriver = that.dirverList.taxi.val;
        that.dirverList.taxi.forEach(item => {
          //		     	//console.log(item.star)
          if (item.star === val) {
            that.labelDriver = that.dirverList.taxi[val - 1];
            that.labelDriver.star = val;
          }
        });
      }
      else if (type == 2) {
        that.dirverList.market.forEach(item => {
          if (item.star == val) {
            that.labelMarker = that.dirverList.market[val - 1];
            that.labelMarker.star = val;
          }
        });
      }
      else if (type == 3) {
        that.dirverList.buy.forEach(item => {
          if (item.star == val) {
            //          that.labelCode=item;/
            that.labelCode = that.dirverList.buy[val - 1];
            that.labelCode.star = val;
          }
        });
      }
      else if (type == 4) {
        that.dirverList.send.forEach(item => {
          if (item.star == val) {
            that.labeldDelivery = item
            that.labeldDelivery = that.dirverList.send[val - 1];
            that.labeldDelivery.star = val;
          }
        });
      }
      else if (type == 5) {
        that.dirverList.carpull.forEach(item => {
          if (item.star == val) {
            that.labelCarpull = item
            that.labelCarpull = that.dirverList.carpull[val - 1];
            that.labelCarpull.star = val;
          }
        });
      }
      else if (type == 6) {
        that.dirverList.food.forEach(item => {
          if (item.star == val) {
            that.labelFood = item
            that.labelFood = that.dirverList.food[val - 1];
            //that.labelFood=item
          }

        });
      } else if (type == 7) {
        console.log('that.dirverList', that.dirverList)
        that.dirverList.appointment.forEach(item => {
          if (item.star == val) {
            that.labelAppointment = item
            that.labelAppointment = that.dirverList.appointment[val - 1];
            //that.labelAppointment=item
          }

        });
      }
    },



  },
  mounted() {

  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

/deep/.el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
}

/deep/.el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

.drop-down-first {
  width: 150px;
  margin-right: 10px;
}

.drop-down-second {
  width: 150px;
}

.search-margin {
  margin-left: 10px;
}

.boxTitle {
  padding: 8px
}

.orderImgBox {
  margin-left: 10px;
  height: 80px;
}

.orderImgBox span {
  font-size: 36px;
}

.user-info-tabs>>>.el-tabs__nav-wrap {
  padding-left: 20px;
}

.innerBox {
  margin-left: 120px;
  padding-top: 20px;

}

.innerBoxTo {
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
}

.innerTitle {
  margin-left: 230px
}

.innerTitle div {
  padding: 2px 10px;
  border-radius: 4px;
  margin: 10px;
  color: #969696;
  border: 1px solid #969696;
  box-sizing: border-box;
  cursor: pointer;

}

.innerTitle div.active {
  padding: 2px 10px;
  border-radius: 4px;
  margin: 10px;
  background: #1CB394;
  border: 1px solid #1CB394;
  color: #fff;
  box-sizing: border-box;
}
</style>

